import { createVuetify } from 'vuetify'
import type { ThemeDefinition } from 'vuetify'

// import '@mdi/font/css/materialdesignicons.css'
import '@/assets/scss/style.scss'

const jpTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#b71918',
    secondary: '#082728',
    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#8fcbd9',
    lightsecondary: '#E8F7FF',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    textPrimary: '#333333',
    textSecondary: '#082728',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#ffffff',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    grey: '#767676',
    white: '#ffffff'
  },
  variables: {
    'border-color': '#e5eaef'
  }
}

export default defineNuxtPlugin(app => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: 'jpTheme',
      themes: { jpTheme }
    },
    defaults: {
      VCard: {
        rounded: 'md',
        elevation: 10
      },

      VTextField: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary'
      },
      VTextarea: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary'
      },
      VSelect: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary'
      },
      VListItem: {
        minHeight: '45px'
      },
      VTooltip: {
        location: 'top'
      },
      VBtn: {
        style: 'text-transform: capitalize; letter-spacing:0',
        rounded: 'md'
      }
    }
  })

  app.vueApp.use(vuetify)
})
