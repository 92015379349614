import { default as datenschutzpI5kXs6pPZMeta } from "/vercel/path0/app/pages/datenschutz.vue?macro=true";
import { default as impressumGwrtgS1pGqMeta } from "/vercel/path0/app/pages/impressum.vue?macro=true";
import { default as indexiGEjlfgJ29Meta } from "/vercel/path0/app/pages/index.vue?macro=true";
import { default as leistungenmc5FGzWWBnMeta } from "/vercel/path0/app/pages/leistungen.vue?macro=true";
import { default as referenzenF0JGuAkajOMeta } from "/vercel/path0/app/pages/referenzen.vue?macro=true";
import { default as teamQwveDVwLQ0Meta } from "/vercel/path0/app/pages/team.vue?macro=true";
export default [
  {
    name: "datenschutz",
    path: "/datenschutz",
    meta: datenschutzpI5kXs6pPZMeta || {},
    component: () => import("/vercel/path0/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: "impressum",
    path: "/impressum",
    meta: impressumGwrtgS1pGqMeta || {},
    component: () => import("/vercel/path0/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexiGEjlfgJ29Meta || {},
    component: () => import("/vercel/path0/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "leistungen",
    path: "/leistungen",
    meta: leistungenmc5FGzWWBnMeta || {},
    component: () => import("/vercel/path0/app/pages/leistungen.vue").then(m => m.default || m)
  },
  {
    name: "referenzen",
    path: "/referenzen",
    meta: referenzenF0JGuAkajOMeta || {},
    component: () => import("/vercel/path0/app/pages/referenzen.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    meta: teamQwveDVwLQ0Meta || {},
    component: () => import("/vercel/path0/app/pages/team.vue").then(m => m.default || m)
  }
]